import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Button, Col, DatePicker, Form, Input, message, Modal, Radio, Row, Select, Spin } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/es/form";
import { createDo, getCIDOSContracts } from "@/api/deliveryOrder";
import { getLocalStorageItem } from "@/utils";
import {
  cutOutCurvesStr,
  daysBetween,
  deviceWidth,
  isHaveRolePermissionFeedFactory,
  isMobileDevice,
  isNorthFactory,
  isVehicleNum
} from "@/utils/common";
import { cutBatchNoZero, formatStrTrim, handleToUpperCase, formatValue, formatNumberValue } from "@/utils/formatUtils";

import TransportTypeItem from "./transportTypeItem";
import ShipAddressItem from "./shipAddressItem";
import AddressIdItem from "./addressIdItem";
import DoQuantityItem from "./doQuantityItem";
import DoQuantityByPackageItem from "./doQuantityByPackageItem";
import DriverNameItem from "./driverNameItem";
import DriverContactNumberItem from "./driverContactNumberItem";
import ExternalMemoItem from "./externalMemoItem";
import TruckPlateNumberItem from "./truckPlateNumberItem";
import TruckLoadingLimitItem from "./truckLoadingLimitItem";

import "./index.scss";
import { queryDetail, queryReamin } from "@/api/deliveryLetterList";
import WarehouseDetailModal from "./warehouseDetailModal";

interface MyProps {
  permission: any;
  doDataSource: any;
  updateDoCreatedListData: Function;
  updateContractListData: (searchParams?: any, actionData?: any) => void;
}

interface MyState {
  doData: object;
  isEdit: boolean;
  isFormLoading: boolean;
  formAllData: object;
  remainQty: number;
  warehouseTradeDetailList: any[];
  startDate: string;
  endDate: string;
  pagination: object;
  ruleDetail: any;
  overLimitTips: JSX.Element | null;
  warehouseLimitTips: JSX.Element | null;
  plantLimitTips: JSX.Element | null;
  showWarehouseDetailModal: boolean;
  doQuantityVA: string
}

class ApplyForm extends Component<MyProps, MyState> {
  formRef = React.createRef<FormInstance>();
  public readonly state = {
    doData: {} as any, // 编辑或者新增，传入的参数都来自doDataSource
    isEdit: false, // 是否可编辑
    isFormLoading: false, // form表单是否需要loading
    // 用作参考字段汇总--仅用于在DOM中使用,来获取表单最新相关值
    formAllData: {
      contractNo: "", // 合同编号
      batch: "", // 合同行项目编号
      buyer: "", // 买方
      packageSpecification: "", // 包装规格
      transportType: "", // 运输方式
      shipAddress: "", // 嘉吉发货地址 -- deliveryLocationCode
      shipAddressDesc: "", // 嘉吉发货地址 -- labelVal
      addressId: "", // 买方收货地址 -- address_line
      receiveAddress: "", // 买方收货地址 -- account_number
      receiveAddressDesc: "", //买方收货地址 -- address_line
      actualRemainingDoQty: "", // 剩余可预制开单量
      doQuantity: "", // 计划提货数量
      doQuantityByPackage: "", // 计划提货数量（件｜箱）
      transportTool: "", // 运输工具
      truckPlateNumber: "", // 车/船号
      truckLoadingLimitOptions: [],
      nominationNumber: "", // 船号
      cycleId: "", // CycleId
      truckLoadingLimit: "", // 车辆限载吨位
      driverName: "", // 承运人姓名
      driverContactNumberOptions: [],
      driverContactNumber: "", // 承运人联系方式
      buyerContactNumber: "", // 买方自有合同号（选填）
      emergencyReceiveAddress: "", // 紧急/一次性买方收货地址（选填）
      shippingRemark: "", // 提单打印信息
      shippingInstruction: "", // 发货要求
      externalMemo: "", // 备注
      estimatedDeliveryDate: "", //预计提货日期
      bigOrder: "", // 大提单
      smsRequired: "" // 离场状态短信通知
    },
    remainQty: 0, // 工厂限量余量
    warehouseTradeDetailList: [], // 仓库限量明细
    startDate: "",
    endDate: "",
    pagination: {
      current: 1,
      total: 0,
      showSizeChanger: true,
      showQuickJumper: true,
      size: deviceWidth() > 576 ? ("default" as any) : ("small" as any),
    },
    ruleDetail: {} as any,
    overLimitTips: null,
    warehouseLimitTips: null,
    plantLimitTips: null,
    showWarehouseDetailModal: false,
    doQuantityVA: ""
  };

  componentDidUpdate(preProps: any) {
    const { doDataSource } = this.props;
    if (doDataSource !== preProps.doDataSource && doDataSource && Object.keys(doDataSource).length) {
      // 更新表单初始化的数据
      this.checkoutAndInitData(doDataSource);

      // 审单日期依据查询
      this.getRuleDetail({
        orgCode: doDataSource.orgCode,
        businessDivision: doDataSource.businessDivision,
        divisionCode: doDataSource.divisionCode
      });
    }
  }

  componentDidMount() {
    const { doDataSource } = this.props;
    if (doDataSource && Object.keys(doDataSource).length > 0) {
      // 设置表单初始化的数据
      this.checkoutAndInitData(doDataSource);

      // 审单日期依据查询
      this.getRuleDetail({
        orgCode: doDataSource.orgCode,
        businessDivision: doDataSource.businessDivision,
        divisionCode: doDataSource.divisionCode
      });
    } else {
      // 没有传入原始数据则清空表单
      this.onDoClearAllFromData();
    }
  }

  // 先检查表单数据，确保数据纯净
  checkoutAndInitData = (sourceData: any) => {
    const { doData } = this.state;
    //如果当前表单存在数据，则需要先全部清空后再重新赋值
    if (doData && Object.keys(doData).length > 0) {
      this.formRef.current?.resetFields();
      this.setState({ isEdit: false, doData: {}, formAllData: {}, doQuantityVA: "" }, () => {
        this.doSetFormInitData(sourceData);
      });
    } else {
      // 直接初始化
      this.doSetFormInitData(sourceData);
    }
  };

  getInitActualRemainingDoQtyOfEdit = (contractData: any, sourceDoQuantity: number) => {
    const sourceActualRemainingDoQtyTemp = contractData?.actualRemainingDoQty || 0;
    const sourceDoQuantityTemp = sourceDoQuantity || 0;
    const actualRemainingDoQtyTemp = sourceActualRemainingDoQtyTemp + sourceDoQuantityTemp;
    return actualRemainingDoQtyTemp ? `${actualRemainingDoQtyTemp.toFixed(3)} (${contractData.packingUnitDesc})` : "-"
  };

  // 初始化表单相关数据
  doSetFormInitData = async (sourceData: any) => {
    // 设置编辑的标识、设置doData传入的原始数据
    let contractData: any = {};
    if (sourceData.doId) {
      // 编辑时需要现请求下合同数据，然后与已创建Do单的数据进行合并
      contractData = await this.getContractDetailData(sourceData);
      this.formRef.current?.setFieldsValue({
        contractNo: formatValue(sourceData.contractNo, "-"),
        batch: cutBatchNoZero(sourceData.batch),
        buyer: formatValue(sourceData.buyer, "-"),
        packageSpecification: formatValue(sourceData.packageSpecificationCn, "-"),
        transportType: formatValue(sourceData.transportType, null),
        shipAddress: formatValue(sourceData.shipAddress, null),
        shipAddressDesc: formatValue(sourceData.shipAddressDesc, ""), // 关联-shipAddress
        addressId: formatValue(sourceData.addressId, null),
        receiveAddress: formatValue(sourceData.receiveAddress, ""), // 关联-addressId
        receiveAddressDesc: formatValue(sourceData.receiveAddressDesc, ""), // 关联-addressId
        actualRemainingDoQty: this.getInitActualRemainingDoQtyOfEdit(contractData, sourceData.doQuantity),
        doQuantity: sourceData.doQuantity ? parseFloat(sourceData.doQuantity.toString()).toFixed(3) : "",
        doQuantityByPackage: formatValue(sourceData.doQuantityByPackage),
        transportTool: formatValue(sourceData.transportTool),
        truckPlateNumber: handleToUpperCase(formatStrTrim(sourceData.truckPlateNumber, true)) || "",
        nominationNumber: formatValue(sourceData.nominationNumber),
        cycleId: formatValue(sourceData.cycleId),
        truckLoadingLimit: formatValue(sourceData.truckLoadingLimit),
        driverName: formatValue(sourceData.driverName),
        driverContactNumber: formatValue(sourceData.driverContactNumber),
        buyerContactNumber: formatValue(sourceData.buyerContactNumber),
        emergencyReceiveAddress: formatValue(sourceData.emergencyReceiveAddress),
        shippingRemark: formatValue(sourceData.shippingRemark),
        shippingInstruction: formatValue(sourceData.shippingInstruction),
        externalMemo: formatValue(sourceData.externalMemo),
        estimatedDeliveryDate: sourceData.estimatedDeliveryDate ? moment(sourceData.estimatedDeliveryDate) : "",
        bigOrder: sourceData.bigOrder || "N",
        smsRequired: sourceData.smsRequired || true
      });
      this.getDoQuantityVAExtra(sourceData.doQuantity, { ...sourceData, ...contractData });
    } else {
      // 新增的情况
      this.formRef.current?.setFieldsValue({
        contractNo: formatValue(sourceData.contractNo, "-"),
        batch: cutBatchNoZero(sourceData.batch),
        buyer: formatValue(sourceData.buyer, "-"),
        packageSpecification: formatValue(sourceData.packageSpecificationCn, "-"),
        transportType: formatValue(sourceData.transportType, null),
        shipAddress: formatValue(sourceData.deliveryLocationCode, null),
        shipAddressDesc: formatValue(sourceData.deliveryLocation, ""), // 关联-shipAddress
        addressId: formatValue(sourceData.addressId, null),
        receiveAddress: formatValue(sourceData.receiveAddress, ""), // 关联-addressId
        receiveAddressDesc: formatValue(sourceData.receiveAddressDesc, ""), // 关联-addressId
        actualRemainingDoQty: sourceData.actualRemainingDoQty
          ? `${sourceData.actualRemainingDoQty.toFixed(3)} (${sourceData.packingUnitDesc})`
          : "-"
      });
    }
    const combinedData = { ...sourceData, ...contractData };
    this.setState({ isEdit: !!combinedData?.doId, doData: { ...combinedData } });
  };

  // 处理嘉吉发货地址切换，设置相关其他值
  onHandleShipAddressChange = (value: string | undefined | null, option: any) => {
    const { permission } = this.props;
    let shipAddressDesc = "";
    if (value) {
      shipAddressDesc = permission.CNInternalUsers ? cutOutCurvesStr(option.label) : option.label;
    }
    this.formRef.current?.setFieldsValue({ shipAddressDesc });
  };

  // 处理买方收货地址切换，设置相关其他值
  onHandleAddressIdChange = (value: string | undefined | null, option: any) => {
    let receiveAddress = "";
    let receiveAddressDesc = "";
    if (value) {
      receiveAddress = option.accountnumber;
      receiveAddressDesc = option.label;
    }
    this.formRef.current?.setFieldsValue({ receiveAddress, receiveAddressDesc });
  };

  clearLimitTips = () => {
    this.setState({
      overLimitTips: null,
      plantLimitTips: null,
      warehouseLimitTips: null
    });
  };

  doOccValidate = (doQuantity: number, dateTime: string) => {
    const { doData } = this.state;
    if (!!doQuantity) {
      this.getRemain({
        orgCode: doData.orgCode,
        customerCode: doData.customerCode,
        businessLine: doData.businessLine ? [doData.businessLine] : [],
        dateTime,
        productCode: doData.productCode
      }, doQuantity)
    } else {
      this.clearLimitTips();
    }
  }

  // accordingTo === 提货委托函提交日期
  occValidateByDoSubmitDate = (doQuantity: string | number, dateTimeStr: string) => {
    if (doQuantity) {
      this.doOccValidate(Number(doQuantity), dateTimeStr);
    } else {
      this.clearLimitTips();
    }
  };

  // accordingTo === 预计提货日期
  occValidateByEstimatedDeliveryDate = (doQuantity: string | number, dateTimeStr: string) => {
    if (dateTimeStr && doQuantity) {
      this.doOccValidate(Number(doQuantity), dateTimeStr);
    } else {
      this.clearLimitTips();
    }
  };

  occValidate = (accordingTo: any, doQuantity: string | number, estimatedDeliveryDate?: any) => {
    if (!doQuantity) {
      this.clearLimitTips();
      return;
    }

    if (accordingTo === "提货委托函提交日期") {
      this.occValidateByDoSubmitDate(Number(doQuantity), moment().format("YYYY-MM-DDTHH:mm"));
    }
    else if (accordingTo === "预计提货日期" && estimatedDeliveryDate) {
      const formattedDate = moment(estimatedDeliveryDate).isSame(moment(), 'day')
        ? moment().format("YYYY-MM-DDTHH:mm")
        : moment(estimatedDeliveryDate).format("YYYY-MM-DDT01:00");
      this.occValidateByEstimatedDeliveryDate(doQuantity, formattedDate);
    }
  };

  // VA业务线 && packingUnit === "PC"时，将件转换为吨，并展示出来
  getDoQuantityVAExtra = (value: any, doData: any) => {
    let doQuantityVA = "";
    if (value) {
      if (doData?.businessDivision === "VA" && doData?.packingUnit === "PC" && doData?.conversionFactorGross) {
        const doQuantity = Number(value);
        doQuantityVA = parseFloat((doQuantity * doData.conversionFactorGross).toString()).toFixed(3);
      }
    } else {
      doQuantityVA = "";
    }
    this.setState({ doQuantityVA });
  };

  // 处理 doQuantity 引起的其他字段的更新,进行赋值
  onHandleDoQuantityChange = (value: string, affectedFields: any) => {
    // 此处值变更可能会带来 doQuantityByPackage 和 bigOrder 字段的影响
    const currentFormData = this.formRef.current?.getFieldsValue(true);
    const affectedFieldsKeys = Object.keys(affectedFields);
    const { ruleDetail, doData } = this.state;

    this.occValidate(ruleDetail?.accordingTo, value, currentFormData.estimatedDeliveryDate);

    if (affectedFields && affectedFieldsKeys.length > 0) {
      if (
        affectedFieldsKeys.includes("doQuantityByPackage") &&
        currentFormData?.doQuantityByPackage !== affectedFields.doQuantityByPackage
      ) {
        this.formRef.current?.setFieldsValue({ doQuantityByPackage: affectedFields.doQuantityByPackage });
      }
      if (affectedFieldsKeys.includes("bigOrder") && currentFormData.bigOrder !== affectedFields.bigOrder) {
        this.formRef.current?.setFieldsValue({ bigOrder: affectedFields.bigOrder });
      }
    }
    this.getDoQuantityVAExtra(value, doData);
  };

  onHandleDoQuantityByPackageChange = (value: string, affectedFields: any) => {
    // 此处值变更可能会带来 doQuantity 字段的影响
    const currentFormData = this.formRef.current?.getFieldsValue(true);
    const affectedFieldsKeys = Object.keys(affectedFields);
    const { ruleDetail } = this.state;

    this.occValidate(ruleDetail?.accordingTo, affectedFields.doQuantity, currentFormData.estimatedDeliveryDate);

    if (affectedFields && affectedFieldsKeys.length > 0) {
      if (affectedFieldsKeys.includes("doQuantity") && currentFormData?.doQuantity !== affectedFields.doQuantity) {
        this.formRef.current?.setFieldsValue({ doQuantity: affectedFields.doQuantity });
      }
    }
  };

  onHandleEstimatedDeliveryDateChange = (date: any, dateString: string) => {
    const { ruleDetail, formAllData } = this.state;
    this.occValidate(ruleDetail?.accordingTo, formAllData.doQuantity, date);
  }

  handleOpenWarehouseRemainDetail = () => {
    this.setState({ showWarehouseDetailModal: true });
  };

  getRuleDetail = (params: any) => {
    const { doData } = this.state;
    this.clearLimitTips();
    queryDetail(params)
      .then((res: any) => {
        if (res?.data && Object.keys(res.data).length) {
          const ruleDetail = Array.isArray(res.data) ? res.data[0] : {};
          this.setState({ ruleDetail });
          // 如果是编辑，则执行初始限量校验
          if (doData?.id && doData.doQuantity) {
            this.occValidate(ruleDetail?.accordingTo, doData.doQuantity, doData.estimatedDeliveryDate);
          }
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
      })
  };

  getRemain = (params: any, doQuantity: number) => {
    let overLimitTipsTemp: JSX.Element | null = null;
    let plantLimitTipsTemp: JSX.Element | null = null;
    let warehouseLimitTipsTemp: JSX.Element | null = null;
    queryReamin(params)
      .then((res: any) => {
        if (res?.data && Object.keys(res.data).length) {
          const warehouseTradeDetailList = Array.isArray(res.data.warehouseTradeDetailList) ? res.data.warehouseTradeDetailList : [];
          const remainQty = res.data.remainQty;
          const status = res.data.status;
          const startDate = res.data.startDate ? moment(res.data.startDate).format("YYYY-MM-DD HH:mm") : "";
          const endDate = res.data.endDate ? moment(res.data.endDate).format("YYYY-MM-DD HH:mm") : "";
          this.setState({ remainQty, warehouseTradeDetailList, startDate, endDate });
          overLimitTipsTemp = this.getOverLimitTips(doQuantity, remainQty);
          plantLimitTipsTemp = this.getPlantLimitTips(remainQty, status);
          warehouseLimitTipsTemp = this.getWarehouseLimitTips(warehouseTradeDetailList);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        this.setState({ remainQty: 0, warehouseTradeDetailList: [], startDate: "", endDate: "" });
      }).finally(() => {
        this.setState({
          overLimitTips: overLimitTipsTemp,
          plantLimitTips: plantLimitTipsTemp,
          warehouseLimitTips: warehouseLimitTipsTemp
        })
      })
  };


  getOverLimitTips = (doQuantity: number, remainQty: number) => {
    if (remainQty !== null && doQuantity > remainQty) {
      return <div className="tips warn">
        <ExclamationCircleOutlined />
        <span className="label">超量提醒：产品采用匀速提货管理，计划提货数量已超过允许提货的最大数量</span>
      </div>
    }
    return null;
  };

  getPlantLimitTips = (remainQty: number, status: string) => {
    const { startDate, endDate } = this.state;
    let plantLimitTipsTemp = "";
    const dateRange = startDate && endDate ? `${startDate} – ${endDate}` : "";
    if (status === "存在限量，以实际审核结果为准") {
      plantLimitTipsTemp = "存在限量，以实际审核结果为准";
    }
    if (remainQty === 0) {
      plantLimitTipsTemp = `限量信息：您可开单的限量余量为：${remainQty}，限制时间范围：${dateRange}`;
    }
    if (remainQty > 0 && remainQty <= 20000) {
      plantLimitTipsTemp = `限量信息：您可开单的限量余量为：${remainQty}，限制时间范围：${dateRange}`;
      if (status === "此余量为集团共享余量") {
        plantLimitTipsTemp = `限量信息：您所在集团共享的开单限量余量为：${remainQty}，限制时间范围：${dateRange}`;
      }
      if (status === "此余量为多客户共享余量") {
        plantLimitTipsTemp = `限量信息：存在多客户共享的开单限量，余量为：${remainQty}，限制时间范围：${dateRange}`;
      }
    }
    return (
      plantLimitTipsTemp ? (
        <div className="tips info">
          <ExclamationCircleOutlined />
          <span className="label">
            {plantLimitTipsTemp}
          </span>
        </div>
      ) : null
    );
  };

  getWarehouseLimitTips = (warehouseTradeDetailList: any[]) => {
    const { permission } = this.props;
    return (warehouseTradeDetailList?.length ? (
      <div className="tips info">
        <ExclamationCircleOutlined />
        <span className="label">存在{permission.CNInternalUsers ? <span style={{ cursor: "pointer", color: "#007681" }} onClick={this.handleOpenWarehouseRemainDetail}>仓库限量</span> : "仓库限量"}，以实际审核结果为准</span>
      </div>) : null
    );
  }

  doWarehouseDetailModalClose = () => {
    this.setState({
      showWarehouseDetailModal: false
    });
  };

  // 动态变化 doQuantityByPackage 输入框单位的展示方式
  doQuantityByPackageLabel = () => {
    const { doData } = this.state;
    return doData.businessDivision === "VA" ? "计划提货数量（箱）" : "计划提货数量（件）";
  };

  // 场景判断
  sceneJudgment = () => {
    const { permission } = this.props;
    const { doData } = this.state;
    const formValues = this.formRef.current?.getFieldsValue(true);
    // 内部用户场景6判断,若满足则不渲染车船号、限载吨位、承运人及联系方式，并在保存时承运人信息传值为空串
    if (permission.CNInternalUsers) {
      // 原场景逻辑 mark: rafu
      if (doData?.buyerSellerOption === "S" && doData?.orgCode === "1006") {
        if (
          ["11", "12", "22", "23"].indexOf(formValues?.transportType) !== -1 &&
          formValues?.shipAddress.slice(0, 2) === "9B"
        ) {
          return true;
        }
      }
    }
    return false;
  };

  // 外部用户非场景3/4/5/7判断，若满足车船号、限载吨位、承运人及联系方式不必填，预计提货日期必填 (非1006oegCode算场景4)
  sceneJudgmentExternal = () => {
    const { permission } = this.props;
    const { doData } = this.state;

    const condition1 = ["配送车", "配送船"].indexOf(doData?.dscTransportType) !== -1;
    const condition2 = doData?.dscTransportType === "外库配送船" && doData?.orgCode !== "1006";
    return permission.CNExternalUsers && (condition1 || condition2);
  };

  // 动态变化的运输方式
  typeOfShipping = () => {
    let value;
    if (this.sceneJudgment()) {
      value = [{ label: "船", value: "船" }];
    } else {
      value = [
        { label: "车", value: "车" },
        { label: "船", value: "船" },
        { label: "火车", value: "火车" }
      ];
    }
    return value;
  };

  // 运输工具的改变需要触发 truckPlateNumber 的校验
  onHandleTransportToolChange = (value: string) => {
    if (value === "火车" || value === "船") {
      this.formRef.current?.setFieldsValue({ bigOrder: "Y" });
    } else if (value === "车") {
      this.formRef.current?.setFieldsValue({ bigOrder: "N" });
    }
  };

  // 承运人-driverName的改变需要触发 driverContactNumber 的变更
  onHandleDriverNameChange = (value: string, options: any) => {
    const { formAllData } = this.state;
    if (value) {
      const contactOptions = options?.entryvalue
        ? options?.entryvalue?.map((value: any) => {
          return { label: value, value: value };
        })
        : [];
      this.setState({
        formAllData: { ...formAllData, driverContactNumberOptions: contactOptions }
      });
    }
  };

  // 车/船号-truckPlateNumber的改变需要触发 truckLoadingLimit 的变更
  onHandleTruckPlateNumberChange = (value: string, options: any) => {
    const { formAllData } = this.state;
    if (value) {
      const contactOptions = options?.entryvalue
        ? options?.entryvalue?.map((value: any) => {
          return { label: value, value: value };
        })
        : [];
      this.setState({
        formAllData: { ...formAllData, truckLoadingLimitOptions: contactOptions }
      });
    }
  };

  // 条件下可选填：（外部用户 && 配送提单 ==> 车/船号、车辆限载吨位、承运人姓名、承运人联系方式可以非必填
  isExternalOptionalFieldsOnConditions = (fieldName: string) => {
    const { permission } = this.props;
    const { doData } = this.state;
    const optionalFields = ["truckPlateNumber", "truckLoadingLimit", "driverName", "driverContactNumber"];
    if (permission.CNExternalUsers && doData.shipmentMethod === "配送") {
      return !optionalFields.includes(fieldName);
    }
    return true;
  };

  // truckLoadingLimit 车辆限载吨位 是否为必填
  isTruckLoadingLimitRequired = () => {
    const { permission } = this.props;
    return permission.CNInternalUsers ? !this.sceneJudgment() : !this.sceneJudgmentExternal();
  };

  // 部分字段的Rules汇总
  returnFieldRules = (field: string) => {
    const { permission } = this.props;
    const formObj = this.formRef.current;
    let rulesData: any = [];
    switch (field) {
      case "driverName":
        rulesData = [
          {
            required: permission.CNInternalUsers
              ? formObj?.getFieldValue("transportTool") !== "其他"
              : this.isExternalOptionalFieldsOnConditions("driverName"),
            message: "请输入承运人姓名"
          },
          () => this.rules_validator_driverName()
        ];
        break;
      case "driverContactNumber":
        rulesData = [
          {
            required: permission.CNInternalUsers
              ? formObj?.getFieldValue("transportTool") !== "其他"
              : this.isExternalOptionalFieldsOnConditions("driverContactNumber"),
            message: "请输入承运人联系方式"
          },
          () => this.rules_validator_driverContactNumber()
        ];
        break;
      case "truckPlateNumber":
        rulesData = [
          {
            required: permission.CNInternalUsers
              ? formObj?.getFieldValue("transportTool") !== "其他"
              : this.isExternalOptionalFieldsOnConditions("truckPlateNumber"),
            message: "请输入车/船号！"
          },
          () => this.rules_validator_truckPlateNumber()
        ];
        break;
      case "truckLoadingLimit":
        rulesData = [
          {
            required: permission.CNInternalUsers
              ? this.isTruckLoadingLimitRequired() && formObj?.getFieldValue("transportTool") !== "其他"
              : this.isExternalOptionalFieldsOnConditions("truckLoadingLimit")
          },
          () => this.rules_validator_truckLoadingLimit()
        ];
        break;
      default:
        rulesData = [];
        break;
    }
    return rulesData;
  };

  // 拆分returnFieldRules中的条件判断，减小方法的复杂度
  rules_validator_driverName = () => {
    return {
      validator(_: any, value: string) {
        const reg = /^[a-zA-Z0-9\u4e00-\u9fa5]+$/;
        if (value && !reg.test(value)) {
          return Promise.reject("承运人姓名应为中文、英文或数字组成！");
        }
        return Promise.resolve();
      }
    };
  };

  rules_validator_driverContactNumber = () => {
    const formObj = this.formRef.current;
    return {
      validator(_: any, value: string) {
        const reg = /^[1-9]\d{10}$/;
        if (value && !reg.test(value)) {
          if (value.toString().length === 1) {
            value = value.replace(/[^1-9]/g, "");
          } else {
            value = value.replace(/\D/g, "");
          }
          if (value.length > 11) {
            value = value.substring(0, 11);
          }
          formObj?.setFieldsValue({ driverContactNumber: value });
          return Promise.reject("联系方式应为11位数字！");
        }
        return Promise.resolve();
      }
    };
  };

  rules_validator_truckPlateNumber = () => {
    const formObj = this.formRef.current;
    return {
      validator(_: any, value: string) {
        if (value && formObj?.getFieldValue("transportTool") === "车" && !isVehicleNum(value)) {
          return Promise.reject("请输入正确格式的车牌号！");
        }
        return Promise.resolve();
      }
    };
  };

  rules_validator_truckLoadingLimit = () => {
    const formObj = this.formRef.current;
    return {
      validator(_: any, value: string) {
        const reg = /^\d+$|^\d*\.\d+$/;
        if (value && !reg.test(value)) {
          value = value.replace(/[^\d^.]+/g, "");
          formObj?.setFieldsValue({ truckLoadingLimit: value });
          return Promise.reject("车辆限载吨位只能由数字组成！");
        }
        return Promise.resolve();
      }
    };
  };

  // 限制可选日期
  onGetEstimatedDeliveryDate = (current: moment.Moment) => {
    return current && current < moment().subtract(1, "days");
  };

  // 针对计量单位的校验
  packingUnitCheck = (values: any) => {
    const { doData, doQuantityVA } = this.state;

    // 物料单位为吨或袋，需要判断是否晁错车辆限载吨位
    // 物料单位为吨，直接与车辆限载吨位比较
    if (
      (doData.packingUnit === "TO" || doData.packingUnit === "MT") &&
      parseFloat(values.doQuantity) > parseFloat(values.truckLoadingLimit)
    ) {
      return false;
    }

    // 物料单位为件，反算为吨后与车辆限载吨位比较
    if (doData.packingUnit === "BAG" && doData.conversionFactor) {
      const doQuantityByPackage = parseFloat(values.doQuantity);
      const calValue = parseFloat((doQuantityByPackage * doData.conversionFactor).toString()).toFixed(3);
      if (parseFloat(calValue) > parseFloat(values.truckLoadingLimit)) {
        return false;
      }
    }

    if (doData.packingUnit === "KG" && doData.conversionFactor) {
      const doQuantityMT = parseFloat(values.doQuantity) * 0.001;
      if (doQuantityMT > parseFloat(values.truckLoadingLimit)) {
        return false;
      }
    }

    if (isNorthFactory(doData.orgCode) &&
      doData.packingUnit === "PC" &&
      doData?.businessDivision === "VA" &&
      doQuantityVA &&
      parseFloat(doQuantityVA) > parseFloat(values.truckLoadingLimit)) {
      return false;
    }
    return true;
  };

  // 针对外部用户特殊处理
  CNExternalUsersSpecialCheck = (values: any) => {
    if (!values.estimatedDeliveryDate) {
      values.estimatedDeliveryDate = moment(new Date()).format("YYYY-MM-DD");
      this.formRef.current?.setFieldsValue({ estimatedDeliveryDate: moment(new Date()) });
    }
    return values;
  };

  // 监测表单中值的变化
  onFormValueChange = (changeVales: any, allValues: any) => {
    const { formAllData } = this.state;
    this.setState({ formAllData: { ...formAllData, ...allValues } });
  };

  // 编辑时、新增提交后获取当前DO单对应合同的最新数据，需要和 doData进行合并，确保全局使用唯一的数据源daData
  getContractDetailData = async (contractParamsData: any) => {
    const data = {
      customerCode: contractParamsData.customerCode === null ? "null" : contractParamsData.customerCode,
      divisionCode: contractParamsData.divisionCode,
      contractNo: contractParamsData.contractNo,
      batch: contractParamsData.batch,
      orgCode: contractParamsData.orgCode
    };
    this.setState({ isFormLoading: true });
    return getCIDOSContracts(data)
      .then(async (res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else if (res?.data?.contractList) {
          return res.data.contractList.length > 0 ? res.data.contractList[0] : {};
        }
        return {};
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取合同数据失败，请稍后重试。");
        return {};
      })
      .finally(() => {
        this.setState({ isFormLoading: false });
      });
  };

  isProvisional = (data: any, type: string) => {
    return type === "暂定价合同" ? "" : data;
  };

  // 创建DO但提交数据字段信息
  createPostData = (formData: any) => {
    const { doData } = this.state;
    // 抽取公共方法--优先从formData取值，没有则从doData内取值
    const revertDataOrigin = (key: string) => {
      return !formData[key] ? doData[key] : formData[key];
    };

    // 变化的值从formData中取，不变的值从doData中取
    return {
      doId: formatValue(doData.doId),
      productName: formatValue(revertDataOrigin("productName")),
      materialSalesText: formatValue(revertDataOrigin("materialSalesText")),
      productCode: formatValue(revertDataOrigin("productCode")),
      contractNo: formatValue(doData.contractNo),
      actualContractNo: formatValue(doData.actualContractNo),
      batch: formatValue(doData.batch),
      buyer: formatValue(doData.buyer),
      packageSpecification: formatValue(doData.packageSpecification),
      packageSpecificationCn: formatValue(doData.packageSpecificationCn),
      conversionFactor: formatNumberValue(doData.conversionFactor),
      conversionFactorGross: formatNumberValue(doData.conversionFactorGross),
      businessDivision: formatValue(doData.businessDivision),
      packageSpecificationNumber: doData.packageSpecificationNumber ?? 0,
      transportType: revertDataOrigin("transportType"),
      shipAddress: formatValue(revertDataOrigin("shipAddress")),
      shipAddressDesc: formatValue(revertDataOrigin("shipAddressDesc")),
      addressId: revertDataOrigin("addressId"),
      receiveAddress: formatValue(revertDataOrigin("receiveAddress")),
      receiveAddressDesc: formatValue(revertDataOrigin("receiveAddressDesc")),
      emergencyReceiveAddress: formatValue(formData.emergencyReceiveAddress),
      doQuantity: formatValue(parseFloat(formData.doQuantity)).toFixed(3),
      doQuantityByPackage: formatValue(formData.doQuantityByPackage),
      cycleId: formatValue(formData.cycleId),
      nominationNumber: formatValue(formData.nominationNumber),
      driverName: formatValue(formData.driverName),
      driverContactNumber: formatValue(formData.driverContactNumber),
      buyerContactNumber: formatValue(formData.buyerContactNumber),
      transportTool: formData.transportTool,
      truckPlateNumber: formatValue(handleToUpperCase(formatStrTrim(formData.truckPlateNumber, true))),
      smsRequired: !!(formData.smsRequired === true || formData.smsRequired === "open" || formData.smsRequired === ""),
      truckLoadingLimit: formatValue(formData.truckLoadingLimit),
      estimatedDeliveryDate: formData.estimatedDeliveryDate
        ? moment(formData.estimatedDeliveryDate).format("YYYY-MM-DD")
        : "",
      shippingRemark: formatValue(formData.shippingRemark),
      shippingInstruction: revertDataOrigin("shippingInstruction"),
      bigOrder: formData.bigOrder,
      externalMemo: formatValue(formData.externalMemo),
      deliveryLocationCode: formatValue(revertDataOrigin("shipAddress")),
      customerId: formatValue(doData.customerId),
      customerCode: formatValue(doData.customerCode),
      businessLine: formatValue(doData.businessLine),
      doMerge: doData.doMerge === true,
      doInternalMerge: doData.doInternalMerge || false,
      doPriority: doData.doPriority ?? 0,
      shipToPartner: formatValue(doData.shipToPartner),
      plantCode: formatValue(doData.plantCode),
      toleranceTypeCode: formatValue(doData.toleranceTypeCode),
      packingCode: formatValue(doData.packingCode),
      settlementUnitPrice:
        !doData.unbilledAmount && doData.unbilledAmount !== 0
          ? this.isProvisional(doData.settlementUnitPrice, formData.contractType)
          : this.isProvisional(doData.unbilledAmount, formData.contractType),
      settlementPriceUnit: doData.settlementPriceUnit,
      actualRemainingDoQty: formatValue(doData.actualRemainingDoQty),
      creditCheckFailCode: doData.creditCheckFailCode,
      creditCheckFailReason: doData.creditCheckFailReason,
      deliveryBasis: doData.basis || doData.deliveryBasis,
      deliveryEndDate: doData.executionEndDate ? moment(doData.executionEndDate).format("YYYY-MM-DD") : "",
      deliveryLocationDescription: isHaveRolePermissionFeedFactory()
        ? JSON.parse(getLocalStorageItem("localEnumInfo"))?.FEED_MODEL?.feedName
        : doData.destinationLocationDescription,
      remainingAvailableDoQty: doData.remainingAvailableDoQty,
      orgCode: doData.orgCode,
      divisionCode: doData.divisionCode,
      buyerSellerOption: doData.buyerSellerOption,
      incotermsCode: doData.incotermsCode,
      packingUnit: revertDataOrigin("packingUnit"),
      packingUnitDesc: revertDataOrigin("packingUnitDesc"),
      orderType: doData.shipmentMethod || doData.orderType || "",
      distrChan: formatValue(doData.distrChan),
      internalContractType: formatValue(doData.internalContractType),
      contractExecutionSequenceControl: daysBetween("", doData.executionStartDate) >= 40 && doData.contractExecutionSequenceControl
    };
  };

  // 创建、编辑提交Do单数据
  createOrEditDo = (formValues: any) => {
    const { updateContractListData, updateDoCreatedListData } = this.props;
    const { doData } = this.state;

    let postData: any = {};
    postData = this.createPostData(formValues);
    postData.action = "save";

    this.setState({ isFormLoading: true });
    let resData: any = [];
    createDo([postData])
      .then(async (res: any) => {
        if (res.code && res.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(res.errorMsg);
        } else if (res.data) {
          resData = res.data;
          if (doData?.doId) {
            // 如果是编辑DO单，则直接全部清除数据。只有在选择合同进行新增才保留上次填写提交的数据
            this.onDoClearAllFromData();
          } else if (resData?.[0].doId) {
            // 生成新DO单成功后, 单独请求相关合同的数据，并更新到全局唯一数据源DoData中
            let newContractData: any = await this.getContractDetailData(doData);
            // 多物料时需要保持跟当前开单选中的物料一致
            if (newContractData?.productList?.length > 1) {
              newContractData = this.keepMultiProductInfo(newContractData);
            }
            // 更新当前的doData数据，主要是更新相关数量的变化
            this.setState({ doData: { ...doData, ...newContractData } });
            // 更新当前form表达中 actualRemainingDoQty 剩余可开开单量的数据
            this.formRef.current?.setFieldsValue({
              actualRemainingDoQty: newContractData?.actualRemainingDoQty?.toFixed(4)
            });
          }

          // 通知--待执行合同自行判断是否需要更新数据
          // 不管是编辑还是新增，都会对合同的剩余可开单量带来变化
          // 新增或编辑时保存时则需要判定合同号是否在目前显示的列表中，有则需要更新列表数据
          updateContractListData(null, resData?.[0] || {});

          // 通知--已建提货委托函--更新列表数据
          if (!postData?.doId) {
            // 新增需要传递doId用于判断 new 标签的显示
            updateDoCreatedListData("add", resData?.[0].doId);
          } else {
            updateDoCreatedListData("edit");
          }
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        message.error("获取数据失败，请稍后重试。");
      })
      .finally(() => {
        this.setState({ isFormLoading: false });
      });
  };

  keepMultiProductInfo = (newContractInfo: any) => {
    const { doData } = this.state;
    // 保证保存前切换的产品信息不变 -- 查询条件没变的情况下记录table中的物料选择信息--只记录选中条目上一次的切换记录
    if (doData && Object.keys(doData).length > 0) {
      const doId1 = newContractInfo.contractNo + cutBatchNoZero(newContractInfo.batch);
      const doId2 = doData.contractNo + cutBatchNoZero(doData.batch);
      if (doId1 === doId2) {
        newContractInfo.packageSpecification = doData.packageSpecification;
        newContractInfo.packageSpecificationCn = doData.packageSpecificationCn;
        newContractInfo.packingUnit = doData.packingUnit;
        newContractInfo.packingUnitDesc = doData.packingUnitDesc;
        newContractInfo.conversionFactor = doData.conversionFactor;
        newContractInfo.conversionFactorGross = doData.conversionFactorGross;
        newContractInfo.productName = doData.productName;
        newContractInfo.materialSalesText = doData.materialSalesText;
        newContractInfo.productCode = doData.productCode;
      }
    }
    return newContractInfo;
  };

  formatByBigOrder = (formValues: any) => {
    const formValuesTemp = { ...formValues };
    const { doData } = this.state;
    if (formValuesTemp.transportTool === "火车" || formValuesTemp.transportTool === "船") {
      formValuesTemp.bigOrder = "Y";
    } else if (
      (doData.packingUnit === "TO" || doData.packingUnit === "MT") &&
      doData.businessDivision === "ACM" &&
      formValuesTemp.doQuantity > 50
    ) {
      formValuesTemp.bigOrder = "Y";
    } else {
      formValues.bigOrder = "N";
    }
    return formValuesTemp;
  };

  truckOverloadCheck = (formValues: any) => {
    const { doData } = this.state;
    return !(formValues?.doQuantity &&
      doData.orgCode === "1006" &&
      isVehicleNum(formValues.truckPlateNumber) &&
      doData.businessDivision === "ACM" &&
      ["40", "42", "01"].includes(doData.divisionCode) &&
      doData.shipmentMethod === "自提" &&
      doData.deliveryLocationCode?.startsWith("1") &&
      parseFloat(formValues?.doQuantity) > 33);
  };

  // 表单 Submit
  onFinish = (fValues: any) => {
    const { permission } = this.props;
    const { doData } = this.state;
    let formValues = this.formRef.current?.getFieldsValue(true);

    if (doData.contractStatusCode === "U") {
      message.error("该合同状态不允许提交委托。");
      return;
    }

    if (!formValues?.doQuantity || formValues.doQuantity === "0") {
      message.warning("计划提货数量为必填项，且不能为0。");
      return;
    }
    const actualRemainingDoQtyVal = formValues.actualRemainingDoQty === "-" ? "0" : formValues.actualRemainingDoQty;

    if (formValues?.doQuantity && parseFloat(formValues?.doQuantity) > parseFloat(actualRemainingDoQtyVal)) {
      message.warning("计划提货数量必须小于等于合同中剩余可开单量。");
      return;
    }

    if (!this.truckOverloadCheck(formValues)) {
      message.error("车提最大提货数量不能超过33吨。");
      return;
    }

    // 计量单位的校验
    if (!this.packingUnitCheck(formValues)) {
      message.error("请注意，您输入的计划提货数量已超过车辆限载吨位。");
      return;
    }
    if (permission.CNExternalUsers) {
      formValues = this.CNExternalUsersSpecialCheck(formValues);
    }

    // 自动判断是否为大提单--手动设置过则无需处理
    if (!formValues.bigOrder) {
      formValues = this.formatByBigOrder(formValues);
    }

    // 此时doQuantity的值表示件数（Sap字段对应需要），还需要赋值给 doQuantityByPackage 字段
    if (doData.businessDivision === "VA" && doData?.packingUnit === "PC") {
      formValues.doQuantityByPackage = formValues.doQuantity;
    }

    // 创建或编辑DO的提交
    this.createOrEditDo(formValues);
  };

  beforeSubmitCheck = () => {
    const { overLimitTips } = this.state;
    if (overLimitTips) {
      Modal.confirm({
        title: "提示",
        icon: <ExclamationCircleOutlined />,
        content: "计划提货数量已超过允许提货的最大数量，请确认是否继续保存？",
        cancelText: "取消",
        okText: "确认",
        onOk: () => {
          this.formRef?.current?.submit();
        }
      })
      return;
    }
    this.formRef?.current?.submit();
  }

  // 表单全部重置处理
  onDoClearAllFromData = () => {
    this.setState({
      isEdit: false,
      doData: {},
      formAllData: {},
      overLimitTips: null,
      warehouseLimitTips: null,
      plantLimitTips: null,
      doQuantityVA: ""
    });
    this.formRef.current?.resetFields();
  };

  public render() {
    const { permission } = this.props;
    const { doData, isFormLoading, formAllData, warehouseTradeDetailList, showWarehouseDetailModal, overLimitTips, warehouseLimitTips, plantLimitTips, doQuantityVA } = this.state;
    return (
      <div className="apply-form-input">
        <Spin spinning={isFormLoading}>
          <Form
            ref={this.formRef}
            layout="vertical"
            autoComplete="off"
            onFinish={this.onFinish}
            onValuesChange={this.onFormValueChange}
            initialValues={{
              contractNo: "-",
              batch: "-",
              buyer: "-",
              packageSpecification: "-",
              transportType: null,
              shipAddress: null,
              addressId: null,
              batchVolume: "-",
              actualRemainingDoQty: "-",
              transportTool: this.sceneJudgment() ? "船" : "车",
              bigOrder: this.sceneJudgment() ? "Y" : "N",
              smsRequired: true
            }}
          >
            <Row className="base-info-row" wrap>
              <Col className="base-info-col">
                <Form.Item name="contractNo">
                  <Input prefix="合同编号" readOnly bordered={false} style={{ paddingLeft: 0 }} />
                </Form.Item>
              </Col>

              <Col className="base-info-col">
                <Form.Item name="batch">
                  <Input prefix="合同行项目编号" readOnly bordered={false} style={{ paddingLeft: 0 }} />
                </Form.Item>
              </Col>

              <Col className="base-info-col">
                <Form.Item name="buyer">
                  <Input title={doData?.buyer} prefix="买方" readOnly bordered={false} style={{ paddingLeft: 0 }} />
                </Form.Item>
              </Col>

              <Col className="base-info-col">
                <Form.Item name="packageSpecification">
                  <Input prefix="包装规格" readOnly bordered={false} style={{ paddingLeft: 0 }} />
                </Form.Item>
              </Col>

              {permission.CNInternalUsers && (
                <Col className="base-info-col">
                  <Form.Item className="apply-form-select-label" name="transportType" label="运输方式">
                    <TransportTypeItem businessDivision={doData.businessDivision} />
                  </Form.Item>
                </Col>
              )}

              <Col className="base-info-col">
                <Form.Item className="apply-form-select-label" name="shipAddress" label="嘉吉发货地址">
                  <ShipAddressItem
                    isCNInternalUsers={permission.CNInternalUsers}
                    shipAddrData={doData.alterDeliveryList}
                    onChange={this.onHandleShipAddressChange}
                  />
                </Form.Item>
              </Col>

              <Col className="base-info-col">
                <Form.Item className="apply-form-select-label" name="addressId" label="买方收货地址">
                  <AddressIdItem
                    customerCode={doData.customerCode}
                    orderType={doData.shipmentMethod ?? doData.orderType ?? ""}
                    onChange={this.onHandleAddressIdChange}
                  />
                </Form.Item>
              </Col>

              <Col className="base-info-col">
                <Form.Item name="actualRemainingDoQty">
                  <Input prefix="剩余可预制开单量" readOnly bordered={false} style={{ paddingLeft: 0 }} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={{ xs: 8, md: 16 }}>
              <Col md={6} xs={24}>
                <Form.Item
                  className="extra-item"
                  label={`计划提货数量 ${doData?.packingUnitDesc ? "(" + doData?.packingUnitDesc + ")" : ""}`}
                  name="doQuantity"
                  rules={[{ required: true }]}
                  extra={
                    doQuantityVA && <div style={{ fontWeight: 600, fontSize: 12, color: "#000" }}>
                      毛重：{doQuantityVA}吨
                    </div>
                  }
                >
                  <DoQuantityItem
                    doData={doData}
                    transportTool={formAllData?.transportTool}
                    onChange={this.onHandleDoQuantityChange}
                  />
                </Form.Item>
              </Col>
              {!(doData.businessDivision === "VA" && doData?.packingCodeDesc === "件") && (
                <Col md={6} xs={24}>
                  <Form.Item label={this.doQuantityByPackageLabel()} name="doQuantityByPackage">
                    <DoQuantityByPackageItem doData={doData} onChange={this.onHandleDoQuantityByPackageChange} />
                  </Form.Item>
                </Col>
              )}
              <Col md={6} xs={24}>
                <Form.Item
                  label="运输工具"
                  name="transportTool"
                  rules={[{ required: true, message: "请输入运输工具" }]}
                >
                  <Select
                    data-testid="transportTool"
                    options={this.typeOfShipping()}
                    placeholder="请选择"
                    onChange={this.onHandleTransportToolChange}
                  />
                </Form.Item>
              </Col>
              {this.sceneJudgment() && permission.CNInternalUsers && (
                <>
                  <Col md={6} xs={24}>
                    <Form.Item label="船号" name="nominationNumber" rules={[{ required: true, message: "请输入船号" }]}>
                      <Input data-testid="nominationNumber" placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col md={6} xs={24}>
                    <Form.Item label="CycleId" name="cycleId" rules={[{ required: true, message: "请输入CycleId" }]}>
                      <Input data-testid="cycleId" placeholder="请输入" />
                    </Form.Item>
                  </Col>
                </>
              )}
              {!this.sceneJudgment() && (
                <>
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="车/船号"
                      name="truckPlateNumber"
                      dependencies={["transportTool"]}
                      rules={this.returnFieldRules("truckPlateNumber")}
                    >
                      <TruckPlateNumberItem onChange={this.onHandleTruckPlateNumberChange} />
                    </Form.Item>
                  </Col>
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="车辆限载吨位"
                      name="truckLoadingLimit"
                      rules={this.returnFieldRules("truckLoadingLimit")}
                    >
                      <TruckLoadingLimitItem truckLoadingLimitOptions={formAllData?.truckLoadingLimitOptions} />
                    </Form.Item>
                  </Col>
                  <Col md={6} xs={24}>
                    <Form.Item label="承运人姓名" name="driverName" rules={this.returnFieldRules("driverName")}>
                      <DriverNameItem onChange={this.onHandleDriverNameChange} />
                    </Form.Item>
                  </Col>
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="承运人联系方式"
                      name="driverContactNumber"
                      rules={this.returnFieldRules("driverContactNumber")}
                    >
                      <DriverContactNumberItem driverContactNumberOptions={formAllData?.driverContactNumberOptions} />
                    </Form.Item>
                  </Col>
                </>
              )}

              <Col md={6} xs={24}>
                <Form.Item label="买方自有合同号（选填）" name="buyerContactNumber">
                  <Input data-testid="buyerContactNumber" placeholder="请输入" />
                </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                <Form.Item label="紧急/一次性买方收货地址（选填）" name="emergencyReceiveAddress">
                  <Input data-testid="emergencyReceiveAddress" placeholder="请输入" />
                </Form.Item>
              </Col>
              {permission.CNInternalUsers && (
                <>
                  <Col md={6} xs={24}>
                    <Form.Item label="提单打印信息" name="shippingRemark">
                      <Input data-testid="shippingRemark" placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col md={6} xs={24}>
                    <Form.Item label="发货要求" name="shippingInstruction">
                      <Input data-testid="shippingInstruction" placeholder="请输入" />
                    </Form.Item>
                  </Col>
                </>
              )}
              {permission.CNExternalUsers && (
                <Col md={6} xs={24}>
                  <Form.Item label="备注（选填）" name="externalMemo">
                    <ExternalMemoItem />
                  </Form.Item>
                </Col>
              )}
              <Col md={6} xs={24}>
                <Form.Item
                  label="预计提货日期"
                  name="estimatedDeliveryDate"
                  rules={[
                    {
                      required: permission.CNInternalUsers ? true : this.sceneJudgmentExternal(),
                      message: "请输入预计提货日期"
                    }
                  ]}
                >
                  <DatePicker
                    data-testid="estimatedDeliveryDate"
                    style={{ width: "100%" }}
                    placeholder="请选择"
                    disabledDate={this.onGetEstimatedDeliveryDate}
                    onChange={this.onHandleEstimatedDeliveryDateChange}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={{ xs: 8, md: 16 }}>
              {/* {doData.contractNo && permission.CNInternalUsers && doData.businessDivision === "ACM" && ( */}
              <Col md={6} xs={24}>
                <Form.Item label="大提单" name="bigOrder">
                  <Radio.Group>
                    <Radio value="Y">是</Radio>
                    <Radio value="N">否</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {/* )} */}
              <Col md={6} xs={24}>
                <Form.Item label="离场状态短信通知" name="smsRequired">
                  <Radio.Group>
                    <Radio value={true}>开启</Radio>
                    <Radio value={false}>不开启</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col md={4} xs={24} style={{ marginLeft: "auto", marginTop: isMobileDevice() ? 0 : 32 }}>
                <div className="hdp-uf hdp-uf-hfe">
                  <Form.Item shouldUpdate className="form-item">
                    <Button
                      data-testid="apply-form-reset"
                      className="form-btn-reset"
                      onClick={this.onDoClearAllFromData}
                    >
                      重置
                    </Button>
                  </Form.Item>
                  <Form.Item shouldUpdate className="form-item">
                    <Button data-testid="submit-btn" type="primary" onClick={this.beforeSubmitCheck} style={{ width: "100%" }}>
                      {doData.doId ? "确认编辑" : "确认新建"}
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
          {overLimitTips}
          {plantLimitTips}
          {warehouseLimitTips}
          {showWarehouseDetailModal && (
            <WarehouseDetailModal
              dataSource={warehouseTradeDetailList}
              doClose={this.doWarehouseDetailModalClose}
            />
          )}
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  permission: state.userPermissions
});

export default connect(mapStateToProps, null)(ApplyForm);
