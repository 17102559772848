import { Component } from "react";
import { Layout, Menu, Spin, message } from "antd";
import { Link, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import {
  DesktopOutlined,
  SettingOutlined,
  UserOutlined,
  AuditOutlined,
  BookOutlined,
  PayCircleOutlined,
  ProfileOutlined,
  SolutionOutlined,
  TeamOutlined,
  NotificationOutlined,
  HourglassOutlined
} from "@ant-design/icons";
import "./cg-sider.scss";
import { downloadUserManualApi } from "@/api/user";
import { isHaveRolePermissionFeedFactory, isMobileDevice, getLocalStorageItem } from "@/utils";
import { getOrganization } from "@/utils/common";

interface Props extends RouteComponentProps {
  collapsedStatus: boolean;
  userDetailsData: any;
  userPermissionsData: any;
  location: any;
}

interface State {
  menuData: any[];
  openKeys: any[];
  selectedKeys: any[];
  menuPermissions: any[];
  userRole: string;
  pathNameCache: any[];
  isOpenUserManualLoading: boolean;
}

const MANUAL_KEYS = process.env.REACT_APP_USER_MANUAL_KEYS;
const { Sider } = Layout;

const rootSubmenuKeys = [
  "usercenter",
  "contractmanagement",
  "delivery",
  "financialmanagement",
  "slot-booking",
  "systemsetting",
  "direct-sys",
  "usermanagement",
  "homenotice"
];

class CgSider extends Component<Props, State> {
  public state = {
    menuData: [],
    openKeys: [],
    selectedKeys: [],
    menuPermissions: [],
    userRole: "",
    pathNameCache: [],
    pathToMenuKeys: {
      contractdetail: ["sapcontractreport"],
      settlementdetail: ["settlementlist"]
    },
    isOpenUserManualLoading: false
  };

  componentDidMount = () => {
    this.initData();
    if (!isMobileDevice()) {
      this.resetOpenAndSelectedKeys();
    }
  };

  componentDidUpdate = (preProps: any) => {
    const lastPathName = preProps.location.pathname;
    const pathName = this.props.location.pathname;
    if (lastPathName !== pathName) {
      if (pathName === "/main/delivery/sapdeliverylist" || pathName === "/main/delivery/deliveryletterlist" || pathName === "/main/financialmanagement/paymentdetails" || pathName === "/main/financialmanagement/accountstatement") {
        const name = pathName.split("/");
        this.setState({ openKeys: [name[2]], selectedKeys: [name[3]] });
      }
    }
  };

  initData = async () => {
    const { userDetailsData, userPermissionsData } = this.props;
    const { groups, entitlements } = userDetailsData;
    // 获取 DSC 维护的用户菜单权限信息
    const myEnumInfo = JSON.parse(getLocalStorageItem("localEnumInfo"));

    // 获取用户角色名
    let userRole: any = "";
    if (userPermissionsData.CNExternalUsers) {
      userRole = getOrganization(entitlements);
    } else {
      userRole = entitlements
        ? entitlements?.[0]?.otherControls?.organizationName
        : myEnumInfo?.[groups?.[0]]?.roleName;
    }
    if (isHaveRolePermissionFeedFactory()) {
      const permissions = myEnumInfo?.[groups?.[0]]?.permissions?.filter((val: any) => val !== "armodule");
      this.setState({
        menuPermissions: permissions,
        userRole: userRole
      });
      return;
    }
    this.setState({
      menuPermissions: myEnumInfo?.[groups?.[0]]?.permissions,
      userRole: userRole
    });
  };

  // 处理菜单展开和关闭
  onOpenChange = (keys: any) => {
    const { openKeys } = this.state;
    const latestOpenKeys = keys.find((key: never) => {
      return openKeys.indexOf(key) === -1;
    });
    if (rootSubmenuKeys.indexOf(latestOpenKeys) === -1) {
      this.resetOpenAndSelectedKeys("open", keys);
    } else {
      this.resetOpenAndSelectedKeys("open", latestOpenKeys ? [latestOpenKeys] : []);
    }
  };

  // 处理最终菜单选择
  onMenuSelect = (menuObj: any) => {
    const { key, selectedKeys, keyPath } = menuObj;
    // 内部用户个人中心除外，不进入菜单选择逻辑
    if (key === "usercenter" && this.props.userPermissionsData?.CNInternalUsers) {
      return;
    }
    if (menuObj && Object.keys(menuObj).length > 0 && keyPath) {
      if ((keyPath.length === 1 && rootSubmenuKeys.indexOf(keyPath[0]) !== -1) || keyPath.length > 1) {
        this.resetOpenAndSelectedKeys("selected", selectedKeys);
        if (keyPath.length === 1) this.resetOpenAndSelectedKeys("open", []);
      }
    } else {
      this.resetOpenAndSelectedKeys("resetAll");
    }
  };

  // Mark：可再进一步优化
  resetOpenAndSelectedKeys = (actionType?: string, keysArr?: any[]) => {
    if (!actionType) {
      // 刷新页面或者直接地址栏访问的场景
      const { pathToMenuKeys } = this.state;
      const hiddenPaths: string[] = ["contractdetail", "settlementdetail"];
      const pathname = this.props.history.location.pathname.split("/");
      if (pathname.length === 4 || pathname.length === 5) {
        const currenSelectedPathname: string = pathname[3];
        if (hiddenPaths.includes(currenSelectedPathname)) {
          this.setState({ openKeys: [pathname[2]], selectedKeys: (pathToMenuKeys as any)[currenSelectedPathname] });
        } else {
          this.setState({ openKeys: [pathname[2]], selectedKeys: [currenSelectedPathname] });
        }
      }
    } else {
      if (actionType === "open") {
        this.setState({ openKeys: keysArr || [] });
      } else if (actionType === "selected") {
        this.setState({ selectedKeys: keysArr || [] });
      } else if (actionType === "resetAll") {
        this.setState({ openKeys: [], selectedKeys: [] });
      }
    }
  };

  // 下载用户手册-内-1｜外-2 用户
  downloadUserManual = () => {
    this.setState({ isOpenUserManualLoading: true });
    const manualKeys = MANUAL_KEYS ? JSON.parse(MANUAL_KEYS) : [];
    const userType: number = this.props.userPermissionsData["CNInternalUsers"] ? 1 : 2;
    if (userType) {
      downloadUserManualApi({}, manualKeys[userType - 1])
        .then((response: any) => {
          if (response) {
            const reg = /^https?:\/\/(.*)/g;
            if (reg.test(response)) {
              window!.open()!.location = response;
            }
          } else {
            message.error("预览失败，请稍后重试。");
          }
        })
        .catch((exception: any) => {
          message.error("预览失败，请稍后重试。");
          console.log(exception);
        })
        .finally(() => {
          this.setState({ isOpenUserManualLoading: false });
        });
    }
  };

  goHome = () => {
    this.resetOpenAndSelectedKeys("resetAll");
    this.props.history.push("/main/home");
  };

  getMenuList = () => {
    const { menuPermissions, userRole } = this.state;
    const { userDetailsData, userPermissionsData } = this.props;
    const { profile } = userDetailsData;
    const roleName = `${profile?.lastName} ${profile?.firstName}`;

    const menuData = [
      {
        label: (
          <Link data-testid="go-to-user-center" to={userPermissionsData["CNExternalUsers"] ? "/main/usercenter" : "#"}>
            {/* 个人中心--内部用户仅展示/外部用户可操作 */}
            <div className="user-role">
              <div className="user-info">
                <div className="role-name">{roleName}</div>
                <div className="role">{userRole}</div>
              </div>
            </div>
          </Link>
        ),
        key: "usercenter",
        icon: <UserOutlined style={{ alignSelf: "baseline", color: "#f5f5f5" }} />,
        isAccessible: true
      },
      {
        label: "合同管理",
        key: "contractmanagement",
        icon: <AuditOutlined data-testid="open-contract-management" />,
        isAccessible:
          (userPermissionsData["CNInternalUsers"] && menuPermissions.includes("contracts" as never)) ||
          (userPermissionsData["CNExternalUsers"] && menuPermissions.includes("contracts_customer" as never)),
        children: [
          {
            label: (
              <Link data-testid="go-to-contract-report" to={"/main/contractmanagement/sapcontractreport"}>
                合同列表
              </Link>
            ),
            key: "sapcontractreport",
            isAccessible: menuPermissions.includes("contractreport" as never)
          },
          {
            label: (
              <Link to={"/main/contractmanagement/chop"}>
                在线签章
              </Link>
            ),
            key: "sap",
            isAccessible: menuPermissions.includes("chop" as never)
          }
        ]
      },
      {
        label: "提货委托函管理",
        key: "delivery",
        icon: <ProfileOutlined />,
        isAccessible:
          (userPermissionsData["CNInternalUsers"] && menuPermissions.includes("deliveryorder" as never)) ||
          (userPermissionsData["CNExternalUsers"] && menuPermissions.includes("deliveryorder_customer" as never)),
        children: [
          {
            label: <Link to={"/main/delivery/consignapply"}>提货委托函申请</Link>,
            key: "consignapply",
            isAccessible: menuPermissions.includes("createdo" as never)
          },
          {
            label: <Link to={"/main/delivery/premadeletter"}>已预制提货委托函</Link>,
            key: "premadeletter",
            isAccessible: menuPermissions.includes("saveddolist" as never)
          },
          {
            label: <Link to={"/main/delivery/deliveryletterlist"}>提货委托函列表</Link>,
            key: "deliveryletterlist",
            isAccessible: menuPermissions.includes("overridedo" as never)
          },
          {
            label: <Link to={"/main/delivery/sapdeliverylist"}>提货单列表</Link>,
            key: "sapdeliverylist",
            isAccessible: menuPermissions.includes("doreport" as never)
          }
        ]
      },
      {
        label: "财务管理",
        key: "financialmanagement",
        icon: <PayCircleOutlined />,
        isAccessible: menuPermissions.includes("armodule" as never),
        children: [
          {
            label: <Link to={"/main/financialmanagement/availablebalance"}>开单额度</Link>,
            key: "availablebalance",
            isAccessible: menuPermissions.includes("arChecking" as never)
          },
          {
            label: <Link to={"/main/financialmanagement/paymentdetails"}>款项明细</Link>,
            key: "paymentdetails",
            isAccessible: menuPermissions.includes("depositReport" as never)
          },
          {
            label: <Link to={"/main/financialmanagement/accountstatement"}>对账单</Link>,
            key: "accountstatement",
            isAccessible: menuPermissions.includes("arChecking" as never)
          },
          {
            label: <Link to={"/main/financialmanagement/invoice"}>发票管理</Link>,
            key: "invoice",
            isAccessible: menuPermissions.includes("invoiceManagement" as never)
          }
        ]
      },
      {
        label: "提货预约",
        key: "slot-booking",
        icon: <SolutionOutlined />,
        isAccessible: menuPermissions.includes("slotbooking" as never),
        children: [
          {
            label: <Link to={"/main/slot-booking/time-window"}>时间窗管理</Link>,
            key: "time-window",
            isAccessible: menuPermissions.includes("timewindowmanagement" as never)
          },
          {
            label: <Link to={"/main/slot-booking/booking"}>预约提货</Link>,
            key: "bookinglist",
            isAccessible: menuPermissions.includes("bookinglist" as never)
          },
          {
            label: <Link to={"/main/slot-booking/booked"}>预约执行列表</Link>,
            key: "booked",
            isAccessible: menuPermissions.includes("bookinghandlelist" as never)
          },
          {
            label: <Link to={"/main/slot-booking/booking-config"}>预约信息配置</Link>,
            key: "booking-config",
            isAccessible: menuPermissions.includes("bookingconfig" as never)
          }
        ]
      },
      {
        label: "功能设置",
        key: "systemsetting",
        icon: <SettingOutlined />,
        isAccessible:
          userPermissionsData["CNInternalUsers"] && menuPermissions.includes("systemsetting_admin" as never),
        children: [
          {
            label: <Link to={"/main/systemsetting/setting"}>站内通知设置</Link>,
            key: "setting",
            isAccessible: menuPermissions.includes("productionsetting" as never)
          },
          {
            label: <Link to={"/main/systemsetting/record"}>历史通知记录</Link>,
            key: "record",
            isAccessible: menuPermissions.includes("productionrecord" as never)
          },
          {
            label: <Link to={"/main/systemsetting/contractupdate"}>合同缓存量刷新</Link>,
            key: "contractupdate",
            isAccessible: menuPermissions.includes("contractupdate" as never)
          },
          {
            label: <Link to={"/main/systemsetting/autoexamine"}>自动审单</Link>,
            key: "autoexamine",
            isAccessible: menuPermissions.includes("autoexamine" as never)
          }
        ]
      },
      {
        label: "限量管理",
        key: "occ",
        icon: <HourglassOutlined />,
        isAccessible: this.isOccAccessible(userPermissionsData, menuPermissions),
        children: [
          {
            label: <Link to={"/main/occ/occremainqty"}>限量余量</Link>,
            key: "occremainqty",
            isAccessible: menuPermissions.includes("occremainqty" as never)
          }
        ]
      },
      {
        label: (
          <div data-testid="go-to-manual" onClick={this.downloadUserManual}>
            <span className="manual-link-text">使用手册</span>
          </div>
        ),
        key: "manual",
        icon: <BookOutlined style={{ color: "#f5f5f5" }} />,
        isAccessible:
          (userPermissionsData["CNInternalUsers"] && menuPermissions.includes("usermanual" as never)) ||
          (userPermissionsData["CNExternalUsers"] && menuPermissions.includes("usermanual_external" as never))
      },
      {
        label: "系统直连",
        key: "direct-sys",
        icon: <DesktopOutlined />,
        isAccessible: userPermissionsData["CNInternalUsers"] && menuPermissions.includes("directconnection" as never),
        children: [
          {
            label: <Link to={"/main/direct-sys/contractconfirmation"}>合同确认</Link>,
            key: "contractconfirmation",
            isAccessible: menuPermissions.includes("contractconfirmation" as never)
          },
          {
            label: <Link to={"/main/direct-sys/pricepointconfirmation"}>点价确认</Link>,
            key: "pricepointconfirmation",
            isAccessible: menuPermissions.includes("pricepointconfirmation" as never)
          },
          {
            label: <Link to={"/main/direct-sys/settlementlist"}>客户结算列表</Link>,
            key: "settlementlist",
            isAccessible: menuPermissions.includes("settlementlist" as never)
          },
          {
            label: <Link to={"/main/direct-sys/dispatchlist"}>嘉吉结算列表</Link>,
            key: "dispatchlist",
            isAccessible: menuPermissions.includes("dispatchlist" as never)
          }
        ]
      },
      {
        label: "用户管理",
        key: "usermanagement",
        icon: <TeamOutlined />,
        isAccessible: userPermissionsData["CNInternalUsers"] && menuPermissions.includes("usermanagement" as never),
        children: [
          {
            label: <Link to={"/main/usermanagement/userlist"}>用户列表</Link>,
            key: "userlist",
            isAccessible: menuPermissions.includes("usermanagement" as never)
          },
          {
            label: <Link to={"/main/usermanagement/errorhandle"}>错误处理</Link>,
            key: "errorhandle",
            isAccessible: menuPermissions.includes("usermanagement" as never)
          }
        ]
      },
      {
        label: "首页通知",
        key: "homenotice",
        icon: <NotificationOutlined />,
        isAccessible: userPermissionsData["CNInternalUsers"] && menuPermissions.includes("usermanagement" as never),
        children: [
          {
            label: <Link to={"/main/usermanagement/homenotice"}>首页通知设置</Link>,
            key: "home-notice",
            isAccessible: menuPermissions.includes("usermanagement" as never)
          }
        ]
      }
    ];

    if (menuData.length > 0) {
      const allowedMenus = this.menuItemFilter(menuData);
      return allowedMenus;
    }
    return menuData;
  };

  menuItemFilter = (menus: any) => {
    return menus
      ?.filter((item: any) => {
        if (item.isAccessible && item.children?.length > 0) {
          item.children = this.menuItemFilter(item.children);
        }
        return item.isAccessible;
      })
      .map((mapItem: any) => {
        delete mapItem.isAccessible;
        return mapItem;
      });
  };

  isOccAccessible = (userPermissionsData: any, menuPermissions: any) => {
    if (menuPermissions) {
      return menuPermissions.includes("occ" as never);
    }
    return false;
  };

  public render() {
    const { openKeys, selectedKeys, isOpenUserManualLoading } = this.state;
    const { collapsedStatus } = this.props;

    return (
      <Sider className="cg-sider" trigger={null} collapsible collapsed={collapsedStatus} width={200}>
        {/* Logo */}
        <div className="hdp-uf hdp-uf-hc cargill-logo-container">
          <div data-testid="btn-go-home" className="logo-content" onClick={this.goHome}>
            <img
              className="site-logo-white-img"
              src={require(`@/assets/images/cargill-logo-white.png`).default}
              alt="cargill-logo-white"
            />
          </div>
        </div>

        {/* 左侧菜单列表 */}
        <Spin spinning={isOpenUserManualLoading}>
          <Menu
            className="sider-menu"
            theme="dark"
            mode="inline"
            items={this.getMenuList()}
            onOpenChange={this.onOpenChange}
            onSelect={this.onMenuSelect}
            openKeys={openKeys}
            selectedKeys={selectedKeys}
          />
        </Spin>
      </Sider>
    );
  }
}

const mapStateToProps = (state: any) => ({
  userDetailsData: state.userDetails,
  userPermissionsData: state.userPermissions
});

export default connect(mapStateToProps, null)(CgSider);
