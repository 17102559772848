import NotificationTips from "@/pages/common/notificationTips";
import { PageHeader, Tabs } from "antd";
import Processing from "./processing";
import "./index.scss";
import Signed from "./signed";

const { TabPane } = Tabs;

const Chop = () => {
  return (
    <div className="chop">
      <PageHeader className="page-header" title="在线签章">
        <NotificationTips />
      </PageHeader>

      <Tabs className="tabs-container">
        <TabPane tab="待签章" key="processing">
          <Processing/>
        </TabPane>
        <TabPane tab="已签章" key="signed">
          <Signed/>
        </TabPane>
      </Tabs>
    </div>
  );
}

export default Chop;