import { useState, useEffect, useCallback } from "react";
import { Modal, Table, message } from "antd";
import { getContractDetailsByRequestNo } from "@/api/contractReport";

// 定义函数组件的props相关参数
interface Props {
  visible: boolean,
  requestNo: string,
  onClose: () => void;
};

const ContractDetailModal: React.FC<Props> = (props) => {
  const [dataSource, setDataSource] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const { visible, onClose, requestNo } = props;

  const columnsData = [
    { title: "合同编号", dataIndex: "contractNo", key: "contractNo" },
    { title: "提货开始日期", dataIndex: "executionStartDate", key: "executionStartDate" },
    { title: "提货截至日期", dataIndex: "executionEndDate", key: "executionEndDate" },
    { title: "物料编码", dataIndex: "materialNo", key: "materialNo" },
    { title: "物料名称", dataIndex: "materialName", key: "materialName" },
    { title: "合同数量", dataIndex: "batchVolume", key: "batchVolume" },
    { title: "合同单价", dataIndex: "price", key: "price" },
    { title: "基差价格", dataIndex: "basis", key: "basis" },
    { title: "交提货方式", dataIndex: "shipmentMethod", key: "shipmentMethod" },
    { title: "交提货地点", dataIndex: "incotermsLocationName", key: "incotermsLocationName" },
    { title: "业务线", dataIndex: "businessLine", key: "businessLine" },
    { title: "合同类型", dataIndex: "contractType", key: "contractType" },
    { title: "合同签订日期", dataIndex: "contractDate", key: "contractDate" },
    { title: "合同点价日期", dataIndex: "priceDate", key: "priceDate" },
    { title: "期货合约月", dataIndex: "dceZce", key: "dceZce" }
  ];

  const getTableDataList = useCallback(() => {
    setLoading(true);
    getContractDetailsByRequestNo({
      requestNo
    })
      .then((responseJSON: any) => {
        if (responseJSON?.code === "99999") {
          // 99999 提示后端的错误信息
          message.error(responseJSON.errorMsg);
        } else if (responseJSON?.data) {
          const tableRecordData = responseJSON.data || [];
          setDataSource(tableRecordData);
        }
      })
      .catch((exception: any) => {
        console.log(`Failure: ${exception}`);
        setDataSource([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [requestNo])

  useEffect(() => {
    if (visible)
      getTableDataList();
  }, [visible, getTableDataList]);

  const handleClose = useCallback(() => {
    setDataSource([]);
    onClose();
  }, [onClose]);

  return (
    <Modal
      centered
      title="合同详情"
      width="60%"
      visible={visible}
      maskClosable={false}
      onCancel={handleClose}
      afterClose={handleClose}
      destroyOnClose={true}
      footer={null}
    >
      <div className="hdp-uf hdp-uf-dc">
        <Table
          size="small"
          loading={loading}
          dataSource={dataSource}
          columns={columnsData}
          pagination={false}
          rowKey="doId"
          scroll={{ x: "max-content" }}
          style={{ minHeight: "200px" }}
        />
      </div>
    </Modal>
  );
};

export default ContractDetailModal;
