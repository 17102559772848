// 存放当前模块本地常量枚举数据

// 路由对应需要的权限对应关系
import UserCenter from "@/pages/userCenter";
import SapContractReport from "@/pages/contractManagement/sapContractReport";
import SapContractDetail from "@/pages/contractManagement/sapContractReport/contractDetail";
import ConsignApply from "@/pages/deliveryOrder/consignApply";
import PreMadeLetter from "@/pages/deliveryOrder/preMadeLetter";
import DeliveryLetterList from "@/pages/deliveryOrder/deliveryLetterList";
import SapDeliveryList from "@/pages/deliveryOrder/sapDeliveryList";
import AvailableBalance from "@/pages/financialManagement/checkAvailableBalance";
import Invoice from "@/pages/financialManagement/invoice";
import Setting from "@/pages/systemSetting/setting";
import Record from "@/pages/systemSetting/record";
import ContractUpdate from "@/pages/systemSetting/contractUpdate";
import ContractConfirmation from "@/pages/direct-sys/contractConfirmation";
import PricePointConfirmation from "@/pages/direct-sys/pricePointConfirmation";
import SettlementList from "@/pages/direct-sys/settlementList";
import settlementDetail from "@/pages/direct-sys/settlementList/settlementDetail";
import DispatchList from "@/pages/direct-sys/dispatchList";
import UserList from "@/pages/userManagement/userList";
import ErrorHandle from "@/pages/userManagement/errorHandle";
import TimeWindow from "@/pages/slotBooking/timeWindow";
import Booking from "@/pages/slotBooking/booking";
import Booked from "@/pages/slotBooking/booked";
import HomeNotice from "@/pages/userManagement/homeNotice";
import BookingConfig from "@/pages/slotBooking/bookingConfig";
import PaymentDetails from "@/pages/financialManagement/paymentDetails";
import AccountStatement from "@/pages/financialManagement/accountStatement";
import AutoExamine from "@/pages/systemSetting/autoExamine";
import OccRemainQty from "@/pages/occ/occRemainQty";
import Chop from "@/pages/contractManagement/chop";

const dynamicRoutingPermissionList = [
  // 个人用户信息展示及维护
  {
    path: "/main/usercenter",
    component: UserCenter,
    viewPermission: ["MyProfileExternalUser"],
    menuPermission: ["user_management"]
  },
  // 合同列表
  {
    path: "/main/contractmanagement/sapcontractreport",
    component: SapContractReport,
    viewPermission: ["ContractReportView"],
    menuPermission: ["sapcontractreport"],
    keepAlive: true
  },
  // // 旧合同列表
  // {
  //   path: "/main/contractmanagement/contractreport",
  //   component: ContractReport,
  //   viewPermission: ["ContractReportView"],
  //   menuPermission: ["contractreport"]
  // },
  // 合同详情
  {
    path: "/main/contractmanagement/sapcontractdetail",
    component: SapContractDetail,
    viewPermission: ["ContractReportView"],
    menuPermission: ["sapcontractreport"]
  },
  // 在线签章
  {
    path: "/main/contractmanagement/chop",
    component: Chop,
    viewPermission: ["ContractReportView"],
    menuPermission: ["chop"]
  },
  // // 旧合同详情
  // {
  //   path: "/main/contractmanagement/contractdetail",
  //   component: ContractDetail,
  //   viewPermission: ["ContractReportView"],
  //   menuPermission: ["contractreport"]
  // },
  // 提货委托函申请
  {
    path: "/main/delivery/consignapply",
    component: ConsignApply,
    viewPermission: ["CreateDOView"],
    menuPermission: ["createdo"]
  },
  // 已预制提货委托函列表
  {
    path: "/main/delivery/premadeletter",
    component: PreMadeLetter,
    viewPermission: ["CreateDOView"],
    menuPermission: ["saveddolist"]
  },
  // 提货委托函列表
  {
    path: "/main/delivery/deliveryletterlist",
    component: DeliveryLetterList,
    viewPermission: ["DOAppEdit"],
    menuPermission: ["overridedo"]
  },
  // 提货单列表
  {
    path: "/main/delivery/sapdeliverylist",
    component: SapDeliveryList,
    viewPermission: ["DOReportView"],
    menuPermission: ["doreport"]
  },
  // // 旧提货单列表
  // {
  //   path: "/main/delivery/deliverylist",
  //   component: DeliveryList,
  //   viewPermission: ["DOReportView"],
  //   menuPermission: ["doreport"]
  // },
  // 查看开单额度
  {
    path: "/main/financialmanagement/availablebalance",
    component: AvailableBalance,
    viewPermission: ["ARView"],
    menuPermission: ["arChecking"]
  },
  {
    path: "/main/financialmanagement/accountstatement",
    component: AccountStatement,
    viewPermission: ["ARView"],
    menuPermission: ["arChecking"],
    keepAlive: true
  },
  // 查看款项明细
  {
    path: "/main/financialmanagement/paymentdetails",
    component: PaymentDetails,
    viewPermission: ["ARView"],
    menuPermission: ["depositReport"]
  },
  // 发票管理
  {
    path: "/main/financialmanagement/invoice",
    component: Invoice,
    viewPermission: ["ARView"],
    menuPermission: ["invoiceManagement"]
  },
  // {
  //   path: "/main/financialmanagement/cts-invoice",
  //   component: CtsInvoice,
  //   viewPermission: ["ARView"],
  //   menuPermission: ["invoiceManagement"]
  // },
  // 站内通知设置
  {
    path: "/main/systemsetting/setting",
    component: Setting,
    viewPermission: ["SystemSetting", "ProductionPlanNotifyEdit"],
    menuPermission: ["productionsetting"]
  },
  // 历史通知记录
  {
    path: "/main/systemsetting/record",
    component: Record,
    viewPermission: ["SystemSetting", "ProductionPlanLogView"],
    menuPermission: ["productionrecord"]
  },
  // 合同缓存量刷新
  {
    path: "/main/systemsetting/contractupdate",
    component: ContractUpdate,
    viewPermission: [],
    menuPermission: ["contractupdate"]
  },
  // 自动审单
  {
    path: "/main/systemsetting/autoexamine",
    component: AutoExamine,
    viewPermission: ["SystemSetting"],
    menuPermission: ["autoexamine"]
  },
  // 限量余量
  {
    path: "/main/occ/occremainqty",
    component: OccRemainQty,
    viewPermission: [],
    menuPermission: ["occremainqty"]
  },
  // 合同确认
  {
    path: "/main/direct-sys/contractconfirmation",
    component: ContractConfirmation,
    viewPermission: [],
    menuPermission: ["contractconfirmation"]
  },
  // 点价确认
  {
    path: "/main/direct-sys/pricepointconfirmation",
    component: PricePointConfirmation,
    viewPermission: [],
    menuPermission: ["pricepointconfirmation"]
  },
  // 客户结算列表
  {
    path: "/main/direct-sys/settlementlist",
    component: SettlementList,
    viewPermission: [],
    menuPermission: ["settlementlist"],
    keepAlive: true
  },
  // 客户结算列表-详情
  {
    path: "/main/direct-sys/settlementdetail",
    component: settlementDetail,
    viewPermission: [],
    menuPermission: ["settlementlist"]
  },
  // 嘉吉磅单列表
  {
    path: "/main/direct-sys/dispatchlist",
    component: DispatchList,
    viewPermission: [],
    menuPermission: ["dispatchlist"]
  },
  // 用户列表
  {
    path: "/main/usermanagement/userlist",
    component: UserList,
    viewPermission: ["UserManageAddUser", "UserManageEditUser"],
    menuPermission: ["userList"]
  },
  // 错误处理
  {
    path: "/main/usermanagement/errorhandle",
    component: ErrorHandle,
    viewPermission: ["UserManageViewUser"],
    menuPermission: ["userList"]
  },
  // 首页通知
  {
    path: "/main/usermanagement/homenotice",
    component: HomeNotice,
    viewPermission: ["UserManageViewUser"],
    menuPermission: ["userList"]
  },
  // 时间窗管理
  {
    path: "/main/slot-booking/time-window",
    component: TimeWindow,
    viewPermission: [],
    menuPermission: ["timewindowmanagement"]
  },
  // 预约提货
  {
    path: "/main/slot-booking/booking",
    component: Booking,
    viewPermission: [],
    menuPermission: ["bookinglist"]
  },
  // 预约执行列表
  {
    path: "/main/slot-booking/booked",
    component: Booked,
    viewPermission: [],
    menuPermission: ["bookinghandlelist"]
  },
  // 预约信息配置
  {
    path: "/main/slot-booking/booking-config",
    component: BookingConfig,
    viewPermission: [],
    menuPermission: ["bookingconfig"]
  }
];

export { dynamicRoutingPermissionList };
