import {
  downloadContractsReport,
  downloadDeliveryOrder,
  downloadDoReport,
  downloadContract,
  downloadPointPrice,
  downloadSettlement,
  downloadOldContractsReport,
} from "@/api/constant";
import { Button, message } from "antd";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import { useCallback, useState } from "react";
import { downloadChopReport } from "@/api/contractReport";

interface Props {
  data: any;
  params: any;
  type?: string;
  filename?: string;
  fileType: string;
  text?: boolean;
  cancelMaxLimit?: boolean;
}

const DownloadReport = (props: Props) => {
  const [loading, setLoading] = useState(false);

  const doDownloadReport = useCallback(() => {
    const { cancelMaxLimit } = props;
    const getDownLoad = (apiName: any) => {
      apiName(props.data, props.params)
        .then((res: any) => {
          if (res.code && res.code === "99999") {
            // 99999 提示后端的错误信息
            message.error(res.errorMsg);
          } else {
            const blob = new Blob([res], { type: props.type || "application/octet-stream" });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", props.filename || "Reports.xls");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        })
        .catch((err: any) => {
          console.log(err);
          message.error("下载失败");
        })
        .finally(() => setLoading(false));
    };

    setLoading(true);
    if (!cancelMaxLimit && props.params.total > 1000) {
      message.error("下载的总条数不能超过1000");
      setLoading(false);
    } else {
      switch (props.fileType) {
        case "report":
          getDownLoad(downloadContractsReport);
          break;
        case "oldreport":
          getDownLoad(downloadOldContractsReport);
          break;
        case "doReport":
          getDownLoad(downloadDoReport);
          break;
        case "deliveryOrder":
          getDownLoad(downloadDeliveryOrder);
          break;
        case "contractsConfirmation":
          getDownLoad(downloadContract);
          break;
        case "pointPrice":
          getDownLoad(downloadPointPrice);
          break;
        case "settlement":
          getDownLoad(downloadSettlement);
          break;
        case "chop":
          getDownLoad(downloadChopReport);
          break;
        default:
          break;
      }
    }
  }, [props]);

  return (
    <>
      <Button
        type={props.text ? "text" : "primary"}
        icon={<VerticalAlignBottomOutlined />}
        onClick={doDownloadReport}
        disabled={props.params.total === 0}
        loading={loading}
      >
        下载报表
      </Button>
    </>
  );
};

export default DownloadReport;
